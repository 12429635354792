var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-card card"},[_c('div',{staticClass:"card-header cyan"},[_c('h1',{staticClass:"text-white"},[_vm._v("İnvertörler")]),_c('div',{staticClass:"header-link"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('router-link',{attrs:{"to":{ name: 'inverters.create' }}},[_c('v-btn',{staticClass:"mx-2 bg-white",attrs:{"fab":""}},[_c('v-icon',{staticClass:"link-icon"},[_vm._v("mdi-plus")])],1)],1)],1)]}}])},[_c('span',[_vm._v(" Ekle ")])])],1)]),_c('div',{staticClass:"card-body py-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"report-body"},[(_vm.getInvertersTableLoading)?_c('div',{staticClass:"report-loading card cyan py-3 mx-auto"},[_vm._m(0)]):_c('table',{staticClass:"table table-sm table-striped table-bordered table-hover w-100",attrs:{"id":"stationsTable"}},[_vm._m(1),_c('tbody',_vm._l((_vm.getInverters),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-nowrap text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('router-link',{attrs:{"to":{
                            name: 'inverters.show',
                            params: { id: item.id },
                          }}},[_c('v-btn',{staticClass:"mx-2 bg-info",attrs:{"fab":"","dark":"","x-small":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Göster")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('router-link',{attrs:{"to":{
                            name: 'inverters.edit',
                            params: { id: item.id },
                          }}},[_c('v-btn',{staticClass:"mx-2 bg-warning",attrs:{"fab":"","dark":"","x-small":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])])],1),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.inverter_id)+" ")]),_c('td',{staticClass:"text-center text-nowrap",attrs:{"data-order":item.timestamp}},[_vm._v(" "+_vm._s(item.dateConvert)+" ")]),_c('td',{staticClass:"text-center text-nowrap"},[_vm._v(" "+_vm._s(item.brand)+" - "+_vm._s(item.model)+" ")]),_c('td',{staticClass:"text-nowrap text-center"},[_vm._v(" "+_vm._s(item.station_field_name)+" ")]),_c('td',{staticClass:"text-nowrap text-center"},[_vm._v(" "+_vm._s(item.datalogger_id)+" ")]),_c('td',{staticClass:"text-nowrap text-right"},[_vm._v(" "+_vm._s(item.connected_panel_count)+" ")]),_c('td',{staticClass:"text-nowrap text-right"},[_vm._v(" "+_vm._s(item.connected_panel_total_power)+" ")]),_c('td',{staticClass:"text-nowrap text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"mx-2 bg-danger",attrs:{"fab":"","dark":"","x-small":""},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Sil")])])],1)])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-white"},[_vm._v(" İnvertörler Yükleniyor "),_c('br'),_vm._v(" Lütfen Bekleyiniz "),_c('i',{staticClass:"fas fa-spinner fa-pulse text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("İşlem")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("İnvertör ID")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Son Veri Zamanı")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("İnvertör Tipi")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Saha Adı")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Datalogger")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Bağlı Panel Sayısı")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v(" Bağlı Panellerin Toplam Gücü ")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Sil")])])])
}]

export { render, staticRenderFns }